exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-contact-index-jsx": () => import("./../../../src/data/pages/contact/index.jsx" /* webpackChunkName: "component---src-data-pages-contact-index-jsx" */),
  "component---src-data-pages-cookiebeleid-index-jsx": () => import("./../../../src/data/pages/cookiebeleid/index.jsx" /* webpackChunkName: "component---src-data-pages-cookiebeleid-index-jsx" */),
  "component---src-data-pages-diarree-bij-kinderen-index-jsx": () => import("./../../../src/data/pages/diarree-bij-kinderen/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-bij-kinderen-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-behandeling-van-reizigersdiarree-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/behandeling-van-reizigersdiarree/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-behandeling-van-reizigersdiarree-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-reisapotheek-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/reisapotheek/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-reisapotheek-index-jsx" */),
  "component---src-data-pages-diarree-op-reis-reizigersdiaree-vermijden-index-jsx": () => import("./../../../src/data/pages/diarree-op-reis/reizigersdiaree-vermijden/index.jsx" /* webpackChunkName: "component---src-data-pages-diarree-op-reis-reizigersdiaree-vermijden-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-buikgriep-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/buikgriep/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-buikgriep-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-chronische-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/chronische-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-chronische-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-diarree-tijdens-uw-menstruatie-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/diarree-tijdens-uw-menstruatie/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-diarree-tijdens-uw-menstruatie-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-eenartsraadplegen-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/eenartsraadplegen/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-eenartsraadplegen-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselallergie-235-n-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselallergie-235-n-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselvergiftiging-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-gezonde-eetgewoonten-voedselvergiftiging-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-norovirus-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-norovirus-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-oorzaken-van-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/oorzaken-van-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-oorzaken-van-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-stress-en-diarree-diarree-examen-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/stress-en-diarree/diarree-examen/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-stress-en-diarree-diarree-examen-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-stress-en-diarree-hoge-werklast-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/stress-en-diarree/hoge-werklast/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-stress-en-diarree-hoge-werklast-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-stress-en-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/stress-en-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-stress-en-diarree-index-jsx" */),
  "component---src-data-pages-feiten-over-diarree-tips-voor-de-behandeling-van-diarree-index-jsx": () => import("./../../../src/data/pages/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-feiten-over-diarree-tips-voor-de-behandeling-van-diarree-index-jsx" */),
  "component---src-data-pages-hoe-werkt-imodium-instant-index-jsx": () => import("./../../../src/data/pages/hoe-werkt-imodium-instant/index.jsx" /* webpackChunkName: "component---src-data-pages-hoe-werkt-imodium-instant-index-jsx" */),
  "component---src-data-pages-hoe-werkt-imodium-instant-werkzame-stof-loperamide-index-jsx": () => import("./../../../src/data/pages/hoe-werkt-imodium-instant/werkzame-stof-loperamide/index.jsx" /* webpackChunkName: "component---src-data-pages-hoe-werkt-imodium-instant-werkzame-stof-loperamide-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-imodium-instant-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/imodium-instant/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-imodium-instant-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-imodiumreg-capsules-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/imodiumreg-capsules/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-imodiumreg-capsules-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-index-jsx" */),
  "component---src-data-pages-imodium-behandelen-diarree-welke-imodium-is-de-juiste-index-jsx": () => import("./../../../src/data/pages/imodium-behandelen-diarree/welke-imodium-is-de-juiste/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-behandelen-diarree-welke-imodium-is-de-juiste-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-juridische-kennisgeving-index-jsx": () => import("./../../../src/data/pages/juridische-kennisgeving/index.jsx" /* webpackChunkName: "component---src-data-pages-juridische-kennisgeving-index-jsx" */),
  "component---src-data-pages-privacybeleid-index-jsx": () => import("./../../../src/data/pages/privacybeleid/index.jsx" /* webpackChunkName: "component---src-data-pages-privacybeleid-index-jsx" */),
  "component---src-data-pages-sitemap-index-jsx": () => import("./../../../src/data/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-data-pages-sitemap-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-gebruik-van-imodium-bij-zwangerschap-of-borstvoeding-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/gebruik-van-imodium-bij-zwangerschap-of-borstvoeding/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-gebruik-van-imodium-bij-zwangerschap-of-borstvoeding-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-geschikte-behandeling-diarree-zonder-inslikken-tabletten-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/geschikte-behandeling-diarree-zonder-inslikken-tabletten/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-geschikte-behandeling-diarree-zonder-inslikken-tabletten-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-heb-ik-een-voorschrift-nodig-voor-imodium-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/heb-ik-een-voorschrift-nodig-voor-imodium/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-heb-ik-een-voorschrift-nodig-voor-imodium-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-kan-imodium-verstopping-veroorzaken-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/kan-imodium-verstopping-veroorzaken/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-kan-imodium-verstopping-veroorzaken-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-moet-ik-diarree-behandelen-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/moet-ik-diarree-behandelen/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-moet-ik-diarree-behandelen-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-mogen-kinderen-imodium-gebruiken-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/mogen-kinderen-imodium-gebruiken/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-mogen-kinderen-imodium-gebruiken-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-oorzaak-terugkerende-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/oorzaak-terugkerende-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-oorzaak-terugkerende-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-je-diarree-tijdens-je-maandstonden-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/waarom-krijg-je-diarree-tijdens-je-maandstonden/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-waarom-krijg-je-diarree-tijdens-je-maandstonden-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-de-oorzaak-van-acute-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-is-de-oorzaak-van-acute-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-de-oorzaak-van-acute-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-diarree-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-is-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-is-diarree-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel-index-jsx" */),
  "component---src-data-pages-veelgestelde-vragen-diarree-wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen-index-jsx": () => import("./../../../src/data/pages/veelgestelde-vragen-diarree/wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen/index.jsx" /* webpackChunkName: "component---src-data-pages-veelgestelde-vragen-diarree-wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen-index-jsx" */),
  "component---src-data-pages-woordenlijst-abdominaal-index-jsx": () => import("./../../../src/data/pages/woordenlijst/abdominaal/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-abdominaal-index-jsx" */),
  "component---src-data-pages-woordenlijst-acute-diarree-index-jsx": () => import("./../../../src/data/pages/woordenlijst/acute-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-acute-diarree-index-jsx" */),
  "component---src-data-pages-woordenlijst-allergie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/allergie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-allergie-index-jsx" */),
  "component---src-data-pages-woordenlijst-antibiotica-index-jsx": () => import("./../../../src/data/pages/woordenlijst/antibiotica/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-antibiotica-index-jsx" */),
  "component---src-data-pages-woordenlijst-antispasmodisch-middel-index-jsx": () => import("./../../../src/data/pages/woordenlijst/antispasmodisch-middel/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-antispasmodisch-middel-index-jsx" */),
  "component---src-data-pages-woordenlijst-bacterien-index-jsx": () => import("./../../../src/data/pages/woordenlijst/bacterien/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-bacterien-index-jsx" */),
  "component---src-data-pages-woordenlijst-centraal-zenuwstelsel-index-jsx": () => import("./../../../src/data/pages/woordenlijst/centraal-zenuwstelsel/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-centraal-zenuwstelsel-index-jsx" */),
  "component---src-data-pages-woordenlijst-chronische-diarree-index-jsx": () => import("./../../../src/data/pages/woordenlijst/chronische-diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-chronische-diarree-index-jsx" */),
  "component---src-data-pages-woordenlijst-constipatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/constipatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-constipatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-darmen-index-jsx": () => import("./../../../src/data/pages/woordenlijst/darmen/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-darmen-index-jsx" */),
  "component---src-data-pages-woordenlijst-dehydratatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/dehydratatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-dehydratatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-diarree-index-jsx": () => import("./../../../src/data/pages/woordenlijst/diarree/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-diarree-index-jsx" */),
  "component---src-data-pages-woordenlijst-flatulentie-of-winderigheid-index-jsx": () => import("./../../../src/data/pages/woordenlijst/flatulentie-of-winderigheid/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-flatulentie-of-winderigheid-index-jsx" */),
  "component---src-data-pages-woordenlijst-gastro-enteritis-of-buikgriep-index-jsx": () => import("./../../../src/data/pages/woordenlijst/gastro-enteritis-of-buikgriep/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-gastro-enteritis-of-buikgriep-index-jsx" */),
  "component---src-data-pages-woordenlijst-immuunsysteem-index-jsx": () => import("./../../../src/data/pages/woordenlijst/immuunsysteem/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-immuunsysteem-index-jsx" */),
  "component---src-data-pages-woordenlijst-index-jsx": () => import("./../../../src/data/pages/woordenlijst/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-index-jsx" */),
  "component---src-data-pages-woordenlijst-laxeermiddelen-index-jsx": () => import("./../../../src/data/pages/woordenlijst/laxeermiddelen/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-laxeermiddelen-index-jsx" */),
  "component---src-data-pages-woordenlijst-loperamide-index-jsx": () => import("./../../../src/data/pages/woordenlijst/loperamide/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-loperamide-index-jsx" */),
  "component---src-data-pages-woordenlijst-menstruatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/menstruatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-menstruatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-misselijkheid-index-jsx": () => import("./../../../src/data/pages/woordenlijst/misselijkheid/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-misselijkheid-index-jsx" */),
  "component---src-data-pages-woordenlijst-norovirus-index-jsx": () => import("./../../../src/data/pages/woordenlijst/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-norovirus-index-jsx" */),
  "component---src-data-pages-woordenlijst-nutrienten-of-voedingsstoffen-index-jsx": () => import("./../../../src/data/pages/woordenlijst/nutrienten-of-voedingsstoffen/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-nutrienten-of-voedingsstoffen-index-jsx" */),
  "component---src-data-pages-woordenlijst-opgeblazen-gevoel-index-jsx": () => import("./../../../src/data/pages/woordenlijst/opgeblazen-gevoel/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-opgeblazen-gevoel-index-jsx" */),
  "component---src-data-pages-woordenlijst-peristaltische-beweging-index-jsx": () => import("./../../../src/data/pages/woordenlijst/peristaltische-beweging/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-peristaltische-beweging-index-jsx" */),
  "component---src-data-pages-woordenlijst-rehydratatie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/rehydratatie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-rehydratatie-index-jsx" */),
  "component---src-data-pages-woordenlijst-sigmoidoscopie-index-jsx": () => import("./../../../src/data/pages/woordenlijst/sigmoidoscopie/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-sigmoidoscopie-index-jsx" */),
  "component---src-data-pages-woordenlijst-simeticon-index-jsx": () => import("./../../../src/data/pages/woordenlijst/simeticon/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-simeticon-index-jsx" */),
  "component---src-data-pages-woordenlijst-slijm-index-jsx": () => import("./../../../src/data/pages/woordenlijst/slijm/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-slijm-index-jsx" */),
  "component---src-data-pages-woordenlijst-stoelgang-index-jsx": () => import("./../../../src/data/pages/woordenlijst/stoelgang/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-stoelgang-index-jsx" */),
  "component---src-data-pages-woordenlijst-symmptoom-index-jsx": () => import("./../../../src/data/pages/woordenlijst/symmptoom/index.jsx" /* webpackChunkName: "component---src-data-pages-woordenlijst-symmptoom-index-jsx" */),
  "component---src-data-pages-zoeken-index-jsx": () => import("./../../../src/data/pages/zoeken/index.jsx" /* webpackChunkName: "component---src-data-pages-zoeken-index-jsx" */)
}

